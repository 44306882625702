<!--服务项目-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col >
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col >
        <el-row class="contentDiv flexContainer">
          <div class="leftCol">
            <div v-for="(item, index) in iconList" :key="index"
                 :class="clickIndex === index ? 'active' : 'unActive'"
                 class="colFlexCenter cursorPointer"
                 @click="showDetail(item, index)">
              <div class="iconDiv colFlexCenter flexAlignItemCenter">
                <img :src="item.src" alt="" class="imgClass" v-show="clickIndex !== index" />
                <img :src="item.clickSrc" alt="" class="imgClass" v-show="clickIndex === index" />
                <div class="infoTitle">{{ item.typeName }}</div>
              </div>
            </div>
          </div>
          <div class="htmlDiv" >
            <div class="w-e-text-container">
              <div v-html="content" data-slate-editor></div>
            </div>
            <!--附件-->
            <div class="label_contain" v-show="file && file.length > 0">
              <div class="fileLabelDiv">附件：</div>
              <div>
                <div v-for="(item, index) in file" :key="index" class="fileDiv">
                  <span class="fileSpanDiv cursorPointer" @click="downloadFile(item)">{{item.fileName}}</span>
                </div>
              </div>
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import { echoImg } from '@/utils/commonApi'
import { downLoadFile } from '@/utils/commonApi'

export default {
  name: 'serviceItems',
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '大厦服务',
          path: '/giEdifice/serviceItems'
        },
      ],
      iconList: [],
      content: '',
      clickIndex: 0,
      file: [],//附件
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    showDetail(data, index) {
      this.clickIndex = index;
      this.content = data.serviceInfo || '';

      //附件
      if (data.file != "") {
        let fileIds = data.file;
        let param = {
          fileIds: fileIds
        }
        axios({
          method: "get",
          url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/uploadFilesQuery',
          params: param
        }).then(res => {
          if (res.data.code === 10000 || res.data.code === 200) {
            this.file = res.data.data
          } else {
            this.$message.warning("附件请求异常")
          }
        }).catch(err => {
          this.$message.error('附件请求失败')
        })
      } else {
        this.file = []
      }

      this.$forceUpdate()
    },
    getData() {
      this.iconList = [];
      this.content = '';
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/item/list",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data;
          if (data && data.length > 0) {
            data.forEach(item => {
              item.src = echoImg(item.typePic);
              item.clickSrc = echoImg(item.typeClickPic);
            })
            this.iconList = data;
            this.content = data[0].serviceInfo || '';
            //附件
            let fileIds = data[0].file;
            if (fileIds != "") {
              let param = {
                fileIds: fileIds
              }
              axios({
                method: "get",
                url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/uploadFilesQuery',
                params: param
              }).then(res => {
                if (res.data.code === 10000 || res.data.code === 200) {
                  this.file = res.data.data
                } else {
                  this.$message.warning("附件请求异常")
                }
              }).catch(err => {
                this.$message.error('附件请求失败')
              })
            } else {
              this.file = []
            }
          }
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },

    //附件下载
    downloadFile(file) {
      let fileInfo = {
        fileId: file.id,
        fileName: file.fileName
      }
      downLoadFile(fileInfo)
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.contentDiv {
  background: white;
  margin: 10px 0 40px;
}
.leftCol {
  width: 438px;
  min-height: 460px;
  background: #EBF3FC;
  display: grid;
  grid-template-columns: auto auto auto;
  //grid-gap: 15px 0;
  padding: 15px 10px;
}
.iconDiv {
  height: 90%;
}
.imgClass {
  width: 33px;
  height: 33px;
}
.active {
  width: 123px;
  height: 90px;
  margin: 10px 10px;
  background: linear-gradient(180deg, #34D4F4 0%, #186CF5 100%);
  border-radius: 5px;

  .infoTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    margin-top: 5px;
  }
}
.unActive {
  width: 123px;
  height: 90px;
  margin: 10px 10px;
  background: #F2F8FF;
  border-radius: 5px;

  .infoTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin-top: 5px;
  }
}
.htmlDiv {
  padding: 20px;
  max-width: calc(100% - 500px);
}
.label_contain {
  display: flex;
  align-items: center;
}
.fileLabelDiv {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  color: #3E3A39;
  line-height: 24px;
  align-self: flex-start;
}
.fileDiv {
  text-align: left;
  .fileSpanDiv {
    padding: 5px 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 28px;
  }
}
</style>
